<template>
  <div class="container max-w-screen-xl mx-auto">
    <v-overlay :value="overlayLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- CONTENT header Start -->  
    <v-card
      elevation="6"
      color="blue-grey lighten-4">
      
      <div class="grid grid-cols-12">
        <span class="col-span-11 text-sm text-gray-600 font-italic my-1 mx-2">
          {{archiveSummaryArticle.journalYear}}, 
          {{archiveSummaryArticle.titleJournal}} - {{archiveSummaryArticle.romanStyle}}, 
          p.{{archiveSummaryArticle.PageStart}}-{{archiveSummaryArticle.PageEnd}}
        </span>
        <div class="span-col-1 text-center bg-gray-200 shadow-sm cursor-pointer hover:bg-gray-100 rounded-l-xl"
            @click="$router.go(-1)">
          
          <v-icon dark color="#333366">mdi-chevron-triple-left</v-icon>
        </div>
      </div>
    </v-card>
    <!-- CONTENT header End -->  

    <!-- CONTENT tabs Start -->
    <v-card>
      
      <v-tabs vertical icons-and-text color="#333366">
        <v-tab class='shadow-inner'>
          <span class=" ">Article <br/> text</span>
          <v-icon large color="#333366">mdi-text-shadow</v-icon>
        </v-tab>
        <v-tab class='shadow-inner'>
          Article <br/> info
          <v-icon center>
            mdi-information-outline
          </v-icon>
        </v-tab>

      <!--  <v-tab>
          <v-icon center>
            mdi-share-variant-outline
          </v-icon>
          Share
        </v-tab>
        -->

        <v-tab-item>
          <v-card>
            <v-card-text>
              <div class="filter drop-shadow-sm text-justify text-2xl text-black font-sans">
                <div  class="mb-6">
                  <span class="text-3xl select-none">{{archiveSummaryArticle.articleTitle}}</span>
                </div>

                <div>
                  <v-chip
                    label
                    class="mb-6 mr-2"
                    color="#2a6ebb"
                    text-color="white" v-for="author in authors" :key="author.authorFullName">
                    <v-avatar left>
                      <v-icon>mdi-account-circle</v-icon>
                    </v-avatar>
                    {{author.authorFullName}}
                  </v-chip>
                </div>
                
                <div class="text-xl ">
                  <span class="select-none">{{archiveSummaryArticle.abstractText}}</span>
                </div>

                <div class="filter drop-shadow-sml my-2">
                  <v-chip class="mr-2" outlined label small text-color="#2a6ebb" v-for="keyword in keywords" :key="keyword.keyword">
                    <strong>{{keyword.keyword}}</strong>
                  </v-chip>

                </div>

              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        
        <v-tab-item>
          <v-card>
            <v-card-text>
              <div class="text-gray-800">
                
                <div class="my-4">
                  <span class="text-lg md:text-3xl select-none">{{archiveSummaryArticle.articleTitle}}</span>
                </div>
            <v-divider></v-divider>                
                <div>
                  <p class="md:text-2xl">Authors <br/>
                    <span v-for="author in authors" :key="author.authorFullName">
                        <div class="bg-blue-50">
                          <p class="ml-4 md:text-lg text-gray-700">{{author.authorFullName}}</p>
                        </div>
                    </span>
                  </p>
            <v-divider></v-divider>
                  
                </div>
                <p class="md:text-2xl text-gray-800">Copyright information </p>
                <p class="ml-4 md:text-lg text-gray-700">
                  <span class="ml-4"> © Author(s) (or their employer(s)) 2021. No commercial re-use. See rights and permissions. </span>
                  This article is made freely available for use in accordance with "Roentgenologia & Radiologia" website terms and conditions. 
                  You may use, download and print the article for any lawful, non-commercial purpose (including text and data mining) provided that all copyright notices and trade marks are retained.
                
                </p>                
              </div>
              <v-divider></v-divider>
            </v-card-text>
          </v-card>
        </v-tab-item>
       <!-- <v-tab-item>
          <v-card>
            <v-card-text>
              <p class="mb-0">
                Cras sagittis. Phasellus nec sem in justo pellentesque facilisis. Proin sapien ipsum, porta a, auctor quis, euismod ut, mi. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nam at tortor in tellus interdum sagittis.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item> -->
      </v-tabs>

    </v-card>
    <!-- CONTENT tabs Start -->
  </div>     
</template>

<script>
import axios from 'axios';

export default {
    data () {
      return {
        overlayLoading: false,
        articleId: this.$route.params.id,
        authors: [],
        keywords: [],
        archiveSummaryArticle: []
      }
    },
    methods: {
      fetchDataFromServer() {
        this.overlayLoading = true;
        //token log
        //console.log('Bearer ' + this.$store.state.idToken);
      
        axios.post('/archive/article',  [{archivedArticleId: this.articleId}], { headers: {
          //"Authorization": 'Bearer ' + this.$store.state.idToken
          "Authorization": 'Bearer ' + localStorage.getItem('token')
        } })
        .then(data => {
          this.overlayLoading = false;
          //Article text
          this.archiveSummaryArticle = data.data[0];
          //Article Authors
          this.authors = data.data[1];
          //Article Keywords
          this.keywords = data.data[2];
        })
        .catch(error => console.log(error))
        .then(this.overlayLoading = false)        
      },
    },
    mounted(){ 
      this.fetchDataFromServer();
    }
  }
</script>
