<template>
    <div class="container max-w-screen-xl mx-auto">
          <v-overlay :value="overlayLoading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <!-- Year tabs Start -->

          <v-card>
            <v-card-title class="text-center justify-center pt-6">
              <h1 class="text-gray-500 font-weight-bold text-h2">
                Archive
              </h1>
            </v-card-title>
            <v-card-title class="text-center justify-center">
                Full text article are available on request to the editor
            </v-card-title>
            <v-tabs centered v-model="yearsTab">
              <v-tab @click="yearsRange = yearsRange2010; tab = 0; articlesByYear(2010);"> <span class="text-xl"> >2010</span> </v-tab>              
              <v-tab @click="yearsRange = yearsRange2020; tab = 1; articlesByYear(2021);"> <span class="text-xl"> >2020</span> </v-tab>              
              <v-tabs-slider></v-tabs-slider>
            </v-tabs>
          </v-card>
          <v-card>

            <v-tabs
              v-model="tab"
              background-color="#333366"
              centered
              dark
              show-arrows
              icons-and-text>
              <v-tabs-slider></v-tabs-slider>

              <v-tab 
                v-for="(year, idx) in this.yearsRange"
                :key="idx"                
                @click="articlesByYear(year)">
                <span class="text-2xl">{{year}}</span>                
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item
                v-for="year in yearsRange"
                :key="year">                
                
                <v-card flat class="mx-2 md:mx-20 m-y-6">
                  <span class="mx-2 md:-mx-12 md:pr-2 text-2xl text-gray-500 font-bold border-r-4 border-gray-400">
                    {{year}}
                  </span>

                  <div v-for="i in text.length" :key="i" 
                      class="rounded-3xl md:p-6 cursor-pointer hover:shadow-xl hover:bg-gray-200"  
                      @click="articlesById(text[i-1].id)">
                    
                    <div class="grid grid-cols-3">
                      
                      <div class="col-span-3 md:col-span-1 text-center align-baseline">
                        <span class="text-gray-500 font-bold">{{text[i-1].titleJournal}}</span>                        
                      </div>

                      <div class="col-span-3 md:col-span-2">
                        <span style="color:#2a6ebb ">{{text[i-1].articleTitle}}</span>
                      </div>
                            
                    </div>

                    <v-divider></v-divider>
                  </div>
                </v-card>

              </v-tab-item>
            </v-tabs-items>
          </v-card>
          <!-- Year tabs End -->
        

        </div>     
</template>

<script>
import axios from 'axios';

export default {
    data () {
      return {
        overlayLoading: false, 
        yearsTab: 0,
        tab: 0,
        text: [],//[{id:''}],
        yearsRange: [],
        yearsRange2010: [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019],
        yearsRange2020: [2020, 2021, 2022, 2023, 2024],//, '-', '-', '-', '-', '-', '-', '-', '-'],
        articlesTitles: []        
      }
    },
    methods: {
      fetchDataFromServer() {
        //console.log('Fetching Data...Started');
        this.overlayLoading = true;
        //token log
        //console.log('Bearer ' + this.$store.state.idToken);

      axios.post('/archive/articlestitles', this.products, { headers: {
        //"Authorization": 'Bearer ' + this.$store.state.idToken
        
        "Access-Control-Allow-Methods": 'POST, GET, OPTIONS, PUT, DELETE',
        "Access-Control-Allow-Headers": 'X-Requested-With, Content-Type, X-Auth-Token, Origin, Authorization',    
        "Authorization": 'Bearer ' + localStorage.getItem('token')
      } })
      .then(data => {
        this.overlayLoading = false;
        //console.log('Fetching Data...Completed');
        //console.log(data.data);
        
        this.articlesTitles = data.data;

        //go to 2019 year of DATA Archive
        this.yearsRange = this.yearsRange2010;
        this.yearsTab = 0;        
        this.tab = 9;
        this.articlesByYear(2019);
        
      })
      .catch(error => console.log(error))
      .then(this.overlayLoading = false)
      },

      articlesByYear(year){
        this.text = this.articlesTitles.filter((x) => x.journalYear === year);
      },
      articlesById(articleId){
        this.$router.push({ name: 'ArchivedArticle', params: { id: articleId } });
      },
    },
    
    mounted(){
    this.fetchDataFromServer();
    this.yearsRange = this.yearsRange2020;
    }
  }
</script>
